import { t } from '@/utils/i18n'
import { Popover } from 'antd'
import { memo } from 'react'
import { BreakDownProps } from '../../data'
import Price from '../Price'
import './index.less'

const BreakDown = ({ data }: BreakDownProps) => {
  return (
    <div className="breakdown-container">
      <div className="breakdown-header">
        {t('transaction_detail.label.transaction_breakdown')}
        <Popover
          content={
            <div className="popover-content">
              <i className="tips-icon tips-icon-popover" />
              {t('transaction_detail.tips.transaction_breakdown')}
            </div>
          }
          trigger="hover"
          placement="top"
        >
          <i className="tips-icon" />
        </Popover>
      </div>
      <ul className="breakdown-list">
        <li className="breakdown-item">
          <div className="breakdown-info">
            <span className="breakdown-info-key">{t('transaction_detail.label.order_value')}</span>
            <Price currency={false} price={data.orderValue} />
          </div>
          <ul className="sub-breakdown-info">
            <li>
              <span>{t('transaction_detail.label.food_original_price')}</span>
              <Price currency={false} price={data.foodOriginPrice} />
            </li>
            {+data.itemDiscountAmount ? (
              <li>
                <span>{t('transaction_detail.label.item_discounts')}</span>
                <Price currency={false} minus price={data.itemDiscountAmount} />
              </li>
            ) : null}
            {+data.flashSaleDiscountsAmount ? (
              <li>
                <span>{t('transaction_detail.label.flash_sale_discount')}</span>
                <Price currency={false} minus price={data.flashSaleDiscountsAmount} />
              </li>
            ) : null}
          </ul>
        </li>
        {+data.surchargeFee ? (
          <li className="breakdown-item">
            <div className="breakdown-info">
              <span className="breakdown-info-key">
                {t('transaction_detail.label.surcharge_fee')}
              </span>
              <Price currency={false} price={data.surchargeFee} />
            </div>
          </li>
        ) : null}
        {+data.promotionSubsidy ? (
          <li className="breakdown-item">
            <div className="breakdown-info">
              <span className="breakdown-info-key">
                {t('transaction_detail.label.promotion_subsidy')}
                <Popover
                  content={
                    <div className="popover-content">
                      <i className="tips-icon tips-icon-popover" />
                      {t('transaction_detail.tips.promotion_subsidy')}
                    </div>
                  }
                  trigger="hover"
                  placement="top"
                >
                  <i className="tips-icon" />
                </Popover>
              </span>
              <Price currency={false} minus price={data.promotionSubsidy} />
            </div>
            <ul className="sub-breakdown-info">
              {+data.voucherMerchantSubsidyAmount ? (
                <li>
                  <span>{t('transaction_detail.label.food_subsidy')}</span>
                  <Price currency={false} minus price={data.voucherMerchantSubsidyAmount} />
                </li>
              ) : null}
              {+data.voucherShippingSubsidyAmount! ? (
                <li>
                  <span>{t('transaction_detail.label.shipping_subsidy')}</span>
                  <Price currency={false} minus price={data.voucherShippingSubsidyAmount!} />
                </li>
              ) : null}
            </ul>
          </li>
        ) : null}
        <li className="breakdown-item">
          <div className="breakdown-info">
            <span className="breakdown-info-key">{t('transaction_detail.label.amount')}</span>
            <Price price={data.totalAmount} />
          </div>
        </li>
      </ul>
    </div>
  )
}

export default memo(BreakDown)
